.Doty{
  transition: all .2s;

  &.micro{
    width: 2rem;
  }

  &.small{
    width: 18rem;
  }

  &.tiny{
    width: 12rem;
  }
  &.mini{
    width: 5rem;
  }

  &.smaller{
    width: 7rem;
  }

  &.medium{
    width: 24rem;
  }

  &.big{
    width: 35rem;
  }

}
