@import '../../../assets/styles/colors';
.Modal{
  z-index: 1300;
  position: fixed;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  min-width: 20vw;
  min-height: 10vh;
  padding: 2rem;
  border-radius: 2rem;
  background-color: $primary-bg;
  backdrop-filter: blur(.2rem);
  -webkit-backdrop-filter: blur(.2rem);

  &.pink{
          border: .4rem solid $pink-dots;
      }
      &.orangered{
          border: .4rem solid $red-color;
      }
      &.blue{
          border: .4rem solid $color-blue-dark;
      }
      &.pale_blue{
          border: .4rem solid $pale-blue-dots;
      }
      &.opal{
          border: .4rem solid $opal-blue-dots;
      }
      &.orange{
          border: .4rem solid $orange-dots;
      }
      &.pale_green{
          border: .4rem solid $pale-green-color;
      }
      &.yellow{
          border: .4rem solid $yellow-color;
      }
      &.green{
          border: .4rem solid $green-color;
      }
}
