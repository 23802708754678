@import '../../assets/styles/mixins';
@import '../../assets/styles/colors';
.Login{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: repeat;
  background-size: cover;

  &__container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30rem;

    @include respond(phone){
      width: 80%;
    }
  }

  &__img{
    width: 30rem;
  }

  &__box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 30rem;


    @include respond(phone){
      width: 80%;
    }

  }

  &__incorrect{
    font-size: 2rem;
    text-decoration: none;
    color: $orange-dots;
    transition: all .2s;
    text-transform: capitalize;
  }

  &__new-user{
    font-size: 2rem;
    text-decoration: none;
    color: #fff;
    transition: all .2s;
    text-transform: capitalize;
    cursor: pointer;

    &:hover{
      transform: scale(1.05);
    }

    &-title{
      color: white;
      font-size: 6rem;
    }
    &-subtitle{
      color: white;
      font-size: 4rem;
    }
    &-msg{
      color: white;
      font-size: 2rem;
      margin-bottom: 2rem;

      &-error{
        font-size: 2rem;
        color:$orange-dots;
      }
    }

  }

  &__content{
    width: 82rem;
    justify-content: space-around;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
  }

  &__user-created{
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
