@import '../../../assets/styles/colors';
@import '../../../assets/styles/mixins';
.GoBack{
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  color: black;
  transition: all .2s;
  position: fixed;
  top: 10rem;
  left: 3rem;
  z-index: 10;
  background-color: $primary-bg;
  border-radius: 1rem;
  padding: 1rem;
  @include respond(tablet){
    top: 3rem;
  }

  @include respond(phone){
    display: none;
  }

  &-mini{
      display: none;
      color: $color-blue-dark;
      @include respond(phone){
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 1.4rem;
        flex-wrap: wrap;
        position: fixed;
        top: 3rem;
        left: 3rem;
        background-color: $primary-bg;
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
      }
  }

  &:hover{
    transform: scale(1.1);
  }
}
