@import '../../assets/styles/colors';
.Admin{
    position: flex;
    flex-direction: column;

    &__options{
        display: flex;
        justify-content: space-around;
        padding: 10rem 20rem;
    }
    &__option{
      background-color: $primary-bg;
      display: flex;
      flex-direction: column;
      font-size: 3rem;
      align-items: center;
      padding: 2rem;
      border-radius: 1rem;
      cursor: pointer;
      transition: all .2s;

      &:hover{
          transform: scale(1.05);
      }
    }
}
