@import "../../../assets/styles/mixins";
.InteractiveColumn {
  min-width: 26rem;
  height: 100vh;
  position: relative;
  @include respond(portrait) {
    justify-content: center;
    margin-bottom: 2rem;
  }
}
