
$pink-dots: #E70094;
$color-blue-dark: #211650;
$orange-dots: #F38A00;
$opal-blue-dots: #00AF9A;
$pale-blue-dots: #407CC9;

@import 'assets/styles/colors';
@import 'assets/styles/mixins';
*{
  margin:0;
  padding: 0;


}

*,
*::before,
*::after{
  box-sizing: inherit;
}

html{
  box-sizing: border-box;
  font-size: 62.5%;


  @include respond(tablet){
    font-size: 56.25%;
  }
  @include respond(portrait){
    font-size: 50%;
  }
  @include respond(phone){
    font-size: 43.75%;
  }
  @include respond(big-desktop){
    font-size: 75%;
  }

}

body{
  //font-family: 'Open Sans', sans-serif;
  font-family: 'Chewy', cursive;
  letter-spacing: .2rem;
  font-weight: 400;
  line-height: 1.6;
  overflow-x: hidden;
  
}

main{
  overflow-x: hidden;
}

.u-margin-bot-medium{
  margin-bottom: 4rem;
}



a,a:link{
  text-decoration: none;
  color: inherit;
}

ul {
    list-style-type: none;
}
