.WordImg{
  transition: all .2s;


  &.mini{
    width: 4rem;
  }

  &.smaller{
    width: 10rem;
  }

  &.small{
    width: 18rem;
  }

  &.medium{
    width: 27rem;
  }

  &.big{
    width: 35rem;
  }

}
