@import '../../../assets/styles/colors';
.Input{
  font-family: inherit;
  background-color: rgba(white,.9);
  color: $pink-dots;
  border-radius: .5rem;
  border: none;
  width: 100%;
  max-width: 40rem;
  padding-left: 1rem;
  border-bottom: .3rem solid transparent;
  display: block;
  transition: all .2s;
  min-height: .6rem;
  font-size: 20rem;

  &__container{
    width: 100%;
    max-width: 40rem;
    &:not(:last-child){
      margin-bottom: 1.5rem;
    }
  }

  &:focus{
    outline: none;
    box-shadow: 0 1rem 2rem rgba(black,.1);
    border-bottom: .3rem solid $pink-dots;
  }

  &__label{
    font-size: 1.2rem;
    font-weight: 700;
    margin-left: 1rem;
    margin-top: .5rem;
    display: block;
    transition: all .3s;
    color: white;
  }

  &:placeholder-shown + &__label{
    opacity:0;
    visibility: hidden;
    transform: translateY(-4rem);
  }



  &__small{
    height: 3rem;
    font-size: 1.6rem;
  }
}
