@import '../../../assets/styles/colors';
.ConfirmButtons{
  border:.3rem solid $primary-bg;
  width: 100%;
  height: 15rem;
  display: flex;
  justify-content: space-around;
  padding: 3.5rem 3rem 3.5rem 3rem;
  border-radius: 0 0 1rem 1rem ;
  
}
