@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.Streak{
    width: 100%;
    border-radius: 2rem;
    width: 23rem;
    border: .3rem solid $orange-dots;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    &__icon{
      width: 4rem;
      margin-bottom: 1rem;
      @include respond(portrait){
        width: 4rem;
      }
    }

    &__text{
      font-size: 2rem;
      color: $color-blue-dark;
      @include respond(portrait){
        text-align: center;

      }
    }
    margin-bottom: 2rem;

    @include respond(portrait){
      position: fixed;
      top: 2rem;
      right: 2rem;
      border: none;
      flex-direction: column;
      justify-content: center;
      width: 4rem;
      max-width: auto;

    }
}
