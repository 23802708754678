// COLORS
$white-color: #e6e6fc;
$pink-dots: #E70094;
$color-blue-dark: #211650;
$orange-dots: #F38A00;
$opal-blue-dots: #00AF9A;
$pale-blue-dots: #407CC9;
$red-color: #E75753;
$yellow-color: #f5dd29;
$pale-green-color: #ACDF87;
$green-color: #4C9A2A;

$main-bg-color: #ECECEC;
$primary-color: #2ecc71;

$text-color: #191919;
$accent-color: rgb(0, 150, 136);

$warning-color:$red-color;
$alert-color: red;
$default-color: #7a7a7a;



$primary-bg: $white-color;
$secondary-bg: $orange-dots;

$primary-color: white;
//$secondary-color: ;

$default-shadow:0 1.5rem 4rem rgba(#000, .40);
$default-padding:2.5rem 20rem;
$medium-padding:2rem 15rem;
$small-padding:1.7rem 8rem;
$no-padding:1.7rem 3rem;
