@import '../../../../assets/styles/colors';
@import '../../../../assets/styles/mixins';

.QuickLesson{
    width: 100%;
    max-width: 120rem;
    border-radius: 2rem;
    border: .3rem solid $color-blue-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    font-size: 2rem;
    color: $color-blue-dark;
    @include respond(portrait){
        max-width: 30rem;
        display: none;
    }
}
