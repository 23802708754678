@import '../../../assets/styles/colors';
.Button{
    font-family: inherit;
    letter-spacing: .3rem;
    padding: 1rem 6rem;
    background-color: #E70094;
    width: 100%;
    border: none;
    border-radius: .5rem;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    margin: 1rem;
    cursor: pointer;
    transition: all .2s;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40rem;
    min-height: 5rem;


    &.default{
      background-color: $default-color;
      color: white;

      &:hover{
          background-color: darken($default-color, 10);
      }

    }

    &.primary{
      background-color: $color-blue-dark;
      color: white;

      &:hover{
          background-color: lighten($color-blue-dark, 10);
      }
    }

    &.mini{
      width: 2rem;
      height: 2rem;
      padding: 1.5rem;
      margin-left: 1rem;
    }

    &.half{
      max-width: 20rem;
    }


    &:hover{
        background-color: lighten(#E70094, 10);
    }

}
