.Backdrop{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(255,255,255,.4);
  backdrop-filter: blur(.2rem);
  -webkit-backdrop-filter: blur(.2rem);
  z-index: 1000;
  position: fixed;
  top:0;
  left: 0;
  backdrop-filter: blur(.2rem);
  -webkit-backdrop-filter: blur(.2rem);

}
