@import '../../../assets/styles/mixins';
.PopIt{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  position: relative;

  &__popit-game{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__options{
    display:flex;
    height: 15rem;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 2rem;

    @include respond(tablet){
      flex-wrap: wrap;
      height: 20rem;
    }

  }
  &__balloon-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__balloon{
    transform-origin: bottom center;
    transition: all .2s;
    width: 2rem;
  }

  &__dontpop{
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    padding: 0 10rem;
    justify-content: center;
    align-items: center;
  }
  &__score{
    display: flex;
  }

  &__img{
    display: flex;
    justify-content: center;
  }

  &__versus{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 8rem;
    height: 30rem;

    @include respond(phone){
      height: 20rem;
    }

  }



}
