@import '../../../../assets/styles/colors';

.Difficulty{
  width: 100%;

  &:not(:last-child){
    margin-bottom: 2rem;
  }

  &__title{
    display: flex;
    align-items: center;

  }


  &__title-img{
    width: 12rem;
  }

  &__title-text{
    font-size: 4rem;
    color: $color-blue-dark;
    font-weight: bold;
    text-transform: capitalize;
  }
}
