@import '../../assets/styles/mixins';
@import '../../assets/styles/colors';
.Levels{
    //background-color: rgba(white,.9);
    //box-shadow: $default-shadow;
  
    @include respond(portrait){
      flex-direction: column-reverse;
    }


    &__title{
        font-size: 3rem;
    }
}
