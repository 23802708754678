@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';
.Spelling{
  display: flex;
  flex-direction: column;
  align-items: center;


  @include respond(phone){
  
  }

  &__img{
    @include respond(tablet){
      width: 10rem;
      height: 10rem;
    }
    @include respond(portrait){
      width: 8rem;
      height: 8rem;
    }
    @include respond(phone){
      width: 6rem;
      height: 6rem;
    }

  }

  &__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 5rem;

  }
}
