@import "../../../assets/styles/colors";
@import "../../../assets/styles/mixins";
.LoadBar {
  display: flex;
  align-items: center;
  
  justify-content: space-around;
  width: 100%;
  padding: .5rem;
  position: relative;

  &__streak {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    font-size: 1.3rem;
    transition: all 0.2s;
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__fire {
    height: 2rem;
    width: 2rem;
  }

  &__bar {
    border: 0.3rem solid $primary-bg;
    width: 80%;
    background-color: white;
    height: 2rem;
    border-radius: 2rem;
    overflow: hidden;
  }

  &__progress {
    transition: all 1s;
    background-color: $red-color;
    position: relative;
    height: 2rem;
    width: 1%;

    &.orange {
      background-color: $orange-dots;
    }

    &.yellow {
      background-color: $yellow-color;
    }

    &.palegreen {
      width: 80%;
      background-color: $pale-green-color;
    }

    &.green {
      background-color: $green-color;
    }
  }
}
