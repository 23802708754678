@import './assets/styles/colors';
@import './assets/styles/mixins';
.App {
  //background-image: url('./assets/background.png');
  //background-color: $color-blue-dark;
  background-size: contain;
  background-repeat: repeat;
  min-height: 100vh;
  min-width: 100vw;
  position: relative;

}

.header-tertiary{
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 400;
}



table,th,td{
  background-color: $primary-bg;
  border-collapse: collapse;
  border: .1rem solid black;
  font-size:1.5rem;

}


th,td{
  padding: .3rem 1.3rem;
}
