@import '../../../../assets/styles/colors';
.EditLevelWords{
  display: flex;
  flex-direction: column;
  align-items: center;

  &__new-sentence-btn{
    display: flex;
    justify-content: space-around;
  }

  &__btn-change-file{
    font-family: inherit;
    padding: 1rem 6rem;
    width: 100%;
    border: none;
    border-radius: .5rem;
    text-transform: uppercase;
    margin: 1rem;
    cursor: pointer;
    transition: all .2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 40rem;
    min-height: 5rem;
    height: 2rem;
    padding: 1.5rem;
    margin-left: 1rem;
   
  }

  &__hide{
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  &__paginate{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: $primary-bg;
    font-size: 2rem;
    padding: 1rem;

    &-button{
      transition: 0.1s all;
      cursor: pointer;
      margin: 0 1rem;
      &:hover{
        transform: scale(1.1);
      }
    }
  }

  &__justify{
    display: flex;
    justify-content:space-between;

  }

  &__edit{
      cursor: pointer;
  }

  &__container{
      background-color: rgba($primary-bg,.95);
      padding: 2rem 6rem;
      border-radius: .5rem;
  }

  &__sentence-container{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
  }

  &__edit-container{
      min-width: 50vh;
      display: flex;
      flex-direction: column;
      align-items: center;
  }

  &__flex-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  &__grow{
    flex-grow: 3;
  }

  &__change-btn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


}
