@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.Reading{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primary-bg;

  &__title{
    text-align: center;
    font-size: 4rem;
    cursor: pointer;
    background-color: rgba($primary-bg,.9);
    padding: 1rem;
    color: $color-blue-dark;
    border-radius: 1rem;
    transition: all .2s;
    &:not(:last-child){
      margin-bottom: 1rem;
    }
    &:hover{
      transform: scale(1.1);
    }
  }

  &__titles-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2rem;
  }


  &__loading{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    background-color: $primary-bg;
    color: $color-blue-dark;
    font-size: 3rem;
    border-radius: 2rem;
    max-width: 60rem;
  }
  &__paragraph{
    font-size: 4rem;
    display: flex;
    flex-wrap: wrap;
    background-color: rgba($primary-bg,.9);
    color: $color-blue-dark;
    padding: 2rem;
    border-radius: 2rem;
    margin: 0 1rem;
  }

  &__word{
    font-size: 3.7rem;


    position: relative;
    cursor: pointer;
    text-align: justify;


    &:hover .Toolkit{
      display:flex;
    }

    &:not(:last-child){
      margin-right: 1rem;
    }
  }

  &__content{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;

  }
}
.u-margin-bot-medium{
  margin-bottom: 3rem;
}

.test{
  color: #423423;
}
