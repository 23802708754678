@import '../../../assets/styles/mixins';
@import '../../../assets/styles/colors';
.DotBombs{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('../../../assets/images/DotBombs/ground.png');
  background-position:bottom;
  background-size:cover;
  background-repeat:space;
  height: 100vh;
  background-color: $color-blue-dark;

  &__game{
    width: 80vw;
    height: 90vh;

  }

  &__pregame{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  &__gameplay{
    display: flex;
    width: 100%;
    height: 100%;

    @include respond(tablet){
      flex-direction: column;
      justify-content: flex-end;
    }

  }

  &__bombs{
    position: relative;
    display: flex;
    justify-content: space-around;
    width: 70%;
    @include respond(tablet){
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      flex:1;
    }

  }

  &__doty-score{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex:1;
    @include respond(tablet){

      flex:0;
    }
    &-container{
      background-color: $primary-bg;
      padding: 1rem;
      border-radius: 1rem;
    }

  }

  &__input-container{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: orangered;
    height: 5rem;
  }

  &__exit{
    display: flex;
    align-items: center;
    font-size: 3rem;
    position: fixed;
    top:4rem;
    right: 2rem;
    transition: all .2s;
    cursor: pointer;
    height: 6rem;
    color: $primary-bg;

    &:hover{
      transform: scale(1.2);
    }


  }

  &__hearts-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 10rem;
    padding: 2rem 1rem;
  }
  &__heart{
    height: 100%
  }

  &__toggle{
      @include respond(tablet){
        display: none;
      }
  }
  &__toggle2{
    display: none;
    @include respond(tablet){
      display: inline-block;
    }
  }
}
