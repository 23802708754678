.Toolkit{
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom:110%;
  left:50%;
  transform: translateX(-50%);
  border-radius: .5rem;
  transition: all .2s;
  min-width: 10rem;
  min-height: 10rem;
  background-color: white;
  border: solid .1rem #000;
  font-size: 2rem;
  color: black;

  + span:hover &{
    display: flex;
  }


}
