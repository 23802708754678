@import '../../../../../assets/styles/colors';

.LevelSection{
    display: flex;
    flex-direction: column;
    align-items: center;


    &__title{
        color: $color-blue-dark;
        font-size: 3rem;
        margin-bottom: 2rem;
    }

    &__container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}
