@import '../../../../assets/styles/colors';
.Bomb{
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
  transition: all .1s;
  text-align: center;
  font-size: 2rem;
  color: $primary-bg;


  &__img{
    width: 100%;
    transition: all .5s;
  }

  &__explode{
    width: 150%;
  }
}
