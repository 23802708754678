@import '../../../../../../assets/styles/colors';

.LevelWord{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    transition: all .2s;
    cursor: pointer;
    &__toggle{
        z-index: 2000;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100rem;
        height: 100rem;
    }

    &:hover{
      transform: scale(1.1);
    }

    &__img{
        width: 8rem;
    }

    &__modal{
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title{
            font-size: 2rem;
            color: $color-blue-dark;
            margin-bottom: 1rem;
        }

        &__img{
            width: 12rem;
            margin-bottom: 2rem;
        }

    }




    &__image-container{
        
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10rem;
        height: 10rem;
        border-radius: 2rem;
        background-color: $primary-bg ;
        &.pink{
            border: .4rem solid $pink-dots;
            box-shadow: 0 1.5rem 4rem rgba($pink-dots, .40);
        }
        &.orangered{
            border: .4rem solid $red-color;
            box-shadow: 0 1.5rem 4rem rgba($red-color, .40);
        }
        &.blue{
            border: .4rem solid $color-blue-dark;
            box-shadow: 0 1.5rem 4rem rgba($color-blue-dark, .40);
        }
        &.pale_blue{
            border: .4rem solid $pale-blue-dots;
            box-shadow: 0 1.5rem 4rem rgba($pale-blue-dots, .40);
        }
        &.opal{
            border: .4rem solid $opal-blue-dots;
            box-shadow: 0 1.5rem 4rem rgba($opal-blue-dots, .40);
        }
        &.orange{
            border: .4rem solid $orange-dots;
            box-shadow: 0 1.5rem 4rem rgba($orange-dots, .40);
        }
        &.pale_green{
            border: .4rem solid $pale-green-color;
            box-shadow: 0 1.5rem 4rem rgba($pale-green-color, .40);
        }
        &.yellow{
            border: .4rem solid $yellow-color;
            box-shadow: 0 1.5rem 4rem rgba($yellow-color, .40);
        }
        &.green{
            border: .4rem solid $green-color;
            box-shadow: 0 1.5rem 4rem rgba($green-color, .40);
        }
        &.disabled{
            border: .4rem solid $default-color;
            box-shadow: 0 1.5rem 4rem rgba($default-color, .40);
        }



    }

    &__title{
      color: $color-blue-dark;
      font-size: 1.5rem;
    }
}
