.Sound{
  position: relative;
  text-align: center;

  &__icon{
    position: absolute;
    top: -3rem;
    right: -3rem;
    font-size: 8rem;
    color: #E70094;
    display: none;
    z-index: 5;

    &-small{
      font-size: 4rem;
    }

    &-right{
      top: 0;
      right: 0;
    }
  }

  

  &:hover &__icon{
    display: block;
  }
}
