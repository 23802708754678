.Forgot{
  position: fixed;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  & span{
    font-size: 1.8rem;
    color: white;
    margin-bottom: 1rem;
  }

}
