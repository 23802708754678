@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';


.Practice{
  position: relative;
  padding: 2rem;

  @include respond(portrait){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }


}
